import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import nailExamImage from '../../assets/nail-exam.jpg';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './homePage.css';

const HomePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
            duration: 1000,  // Tốc độ hiệu ứng
            once: true,       // Chỉ chạy một lần khi xuất hiện trên màn hình
        });
    }, []);

    const handleStartQuiz = () => {
        navigate('/quizzes');
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Đề Thi Nail California 2025 | Đề Thi Nail Mới Nhất | Thi Nail 2025</title>
                <meta name="description" content="Luyện thi bằng nail với các đề thi mới nhất năm 2025. Đề thi nail California 2025, câu hỏi thi nail tiếng Việt" />
                <meta name="keywords" content="đề thi nail california 2025, đề thi nail mới nhất 2025, thi nail 2025, câu hỏi thi nail tiếng việt mới nhất, đề thi nail tiếng việt, trắc nghiệm nails, luyện thi nails" />
            </Helmet>

            <Container className="homepage-container mb-5">
                {/* Hero Section */}
                <Row className="align-items-center homepage-hero">
                    <Col md={6} className="text-md-left" data-aos="fade-right">
                        <h1 className="homepage-title mb-4">Luyện Thi Chứng Chỉ Nail</h1>
                        <p className="lead mb-4">Chuẩn bị cho kỳ thi với các đề thi trắc nghiệm mới nhất từ California và toàn Mỹ.</p>
                        <Button variant="primary" size="lg" className="mb-4" onClick={handleStartQuiz}>
                            Bắt Đầu Luyện Tập Ngay
                        </Button>
                    </Col>
                    <Col md={6} data-aos="fade-left">
                        <img src={nailExamImage} loading="lazy" alt="Thi chứng chỉ nail" className="img-fluid rounded homepage-image" />
                    </Col>
                </Row>

                {/* Feature Cards */}
                <Row className="my-5">
                    <Col md={4} data-aos="zoom-in">
                        <Card className="h-100 homepage-card">
                            <Card.Body>
                                <Card.Title>Đề Thi Mới Nhất</Card.Title>
                                <Card.Text>Cập nhật liên tục các đề thi từ California và các tiểu bang khác.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} data-aos="zoom-in" data-aos-delay="200">
                        <Card className="h-100 homepage-card">
                            <Card.Body>
                                <Card.Title>Dễ dàng luyện tập</Card.Title>
                                <Card.Text>Truy cập vào các bài thi trắc nghiệm để nâng cao khả năng ghi nhớ để chuẩn bị cho kỳ thi.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} data-aos="zoom-in" data-aos-delay="400">
                        <Card className="h-100 homepage-card">
                            <Card.Body>
                                <Card.Title>Thực hiện thi thử</Card.Title>
                                <Card.Text>Thực hiện các bài thi thử mô phỏng theo bài thi của PSI, làm quen với cuộc thi dễ dàng.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Exam Details */}
                <Row className="my-5">
                    <Col data-aos="fade-up">
                        <h2 className="homepage-section-title">Câu Hỏi Thi Nail Tiếng Việt Mới Nhất 2025</h2>
                        <p className="lead mb-4">
                            Chuẩn bị cho kỳ thi chứng chỉ với <strong>đề thi nail California 2025</strong> và <strong>câu hỏi thi nail tiếng Việt mới nhất 2025</strong>.
                            Luyện tập với các đề thi được cập nhật.
                        </p>
                        <p className="homepage-text">
                            Thi lý thuyết nails là yêu cầu bắt buộc để được cấp bằng hành nghề làm nails tại California. Sau khi hoàn thành khóa học 400 giờ, bạn phải vượt qua bài kiểm tra viết để được cấp bằng. Hiện nay, bài thi thực hành không còn bắt buộc.
                        </p>
                        <p className="homepage-text">
                            Có hiệu lực từ ngày 1 tháng 1 năm 2022, kỳ thi thực hành không còn bắt buộc đối với tất cả các loại giấy phép. Các ứng viên chỉ cần vượt qua kỳ thi viết để được cấp phép.
                        </p>
                    </Col>
                </Row>

                {/* Exam Structure */}
                <Row className="my-5">
                    <Col data-aos="fade-up" data-aos-delay="300">
                        <h2 className="homepage-section-title">Cấu trúc đề thi lý thuyết nails 2025</h2>
                        <p className="homepage-list">
                            Thời gian thi: 90 phút.<br />
                            Câu hỏi trắc nghiệm: 60 câu.<br />
                            Bài pretest: 10 bài.<br />
                        </p>
                        <p>
                            Đề thi tiếng Việt có nhiều câu dịch khá khó hiểu, mời bạn tham khảo thêm{' '}
                            <a
                                href="https://proctor2.psionline.com/media/programs/CADCA/Vietnamese.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="psi-link"
                            >
                                một số thuật ngữ do PSI cung cấp tại đây
                            </a>.
                        </p>
                    </Col>
                </Row>
            </Container>
        </HelmetProvider>
    );
};

export default HomePage;
